import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, FormControl, InputLabel, MenuItem, Select, Box, Input } from '@mui/material';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import adminApi from '../../../app/api/admin';

interface ChangeSubscriptionDialogProps {
  open: boolean;
  selectedUser: any;
  handleClose: () => void;
  postSubmit: () => void;
}

const ChangeSubscriptionDialog: React.FC<ChangeSubscriptionDialogProps> = ({
  open,
  selectedUser,
  handleClose,
  postSubmit,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center' }}>Change Status</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            user_uuid: selectedUser?.uuid,
            subscription_id: selectedUser?.subscription_id ?? 1,
            start_date: selectedUser?.start_date ?? '',
            end_date: selectedUser?.end_date ?? '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            adminApi.changeSubscription(values)
              .then(() => postSubmit())
              .then(() => handleClose())
              .then(() => setSubmitting(false));
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validateForm,
            setFieldValue,
          }) => (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <InputLabel id="order-status-label">Order Status</InputLabel>
              <Select
                value={values.subscription_id}
                label="Order Status"
                labelId="order-status-label"
                name='order_status'
                onChange={handleChange}
              >
                <MenuItem value={1}>Free</MenuItem>
                <MenuItem value={2}>Lifestyle (Monthly)</MenuItem>
                <MenuItem value={3}>Lifestyle (Yearly)</MenuItem>
                <MenuItem value={4}>Lifestyle (Trial)</MenuItem>
                <MenuItem value={5}>Lifestyle (Lifetime)</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <InputLabel id="start-date-label">Start Date</InputLabel>
              <Input
                type="datetime-local"
                value={values.start_date}
                name='start_date'
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: '10px' }}>
              <InputLabel id="end-date-label">End Date</InputLabel>
              <Input
                type="datetime-local"
                value={values.end_date}
                name='end_date'
                onChange={handleChange}
              />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <LoadingButton
                id="signin-button"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                // sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
                sx={{
                  padding: '10px 35px',
                  borderRadius: '30px',
                  backgroundColor: '#373736',
                  color: '#FECD20',
                  '&:hover': {
                    backgroundColor: '#252525',
                    color: '#FECD20',
                  },
                  '&:disabled': {
                    backgroundColor: '#C5C5C5',
                  }
                }}
              >
                <span>{isSubmitting ? 'Working' : 'Save Changes'}</span>
              </LoadingButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={handleClose}>Go Back</Button>
            </div>
          </Box>)}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeSubscriptionDialog;
