import React from "react";
import { Button, Box, Tab, Tabs, Typography, TextField, Menu, MenuItem, Checkbox, Divider, ListItemText, IconButton } from "@mui/material";
import SubscriptionCard from "./component/subscriptionCard";
import ConfirmationDialog from "./component/confirmationDialog";
import FilterDialog from "../order/component/filterDialog";
import icons from "../../assets/icons";
import NFCInvoice from "../order/component/nfcInvoice";

export default function SubscriptionsComponent({
  subs,
  open,
  openSortMenu,
  handleClick,
  setOpen,
  openFilter,
  setOpenFilter,
  anchorEl,
  setAnchorEl,
  sortAnchorEl,
  setSortAnchorEl,
  search,
  setSearch,
  reason,
  setReason,
  selectedSub,
  setSelectedSub,
  selectedTab,
  handleTabChange,
  handleChange,
  handleAllChange,
  handleCancelSubscription,
  downloadCSV,
  downloadPDF,
}) {
  return <>
    <ConfirmationDialog
      open={open}
      selectedSub={selectedSub}
      handleClose={() => setOpen(false)}
      handleCancelSubscription={handleCancelSubscription}
      reason={reason}
      setReason={setReason}
    />
    <FilterDialog
      open={openFilter}
      handleClose={() => setOpenFilter(false)}
      search={search}
      setSearch={setSearch}
    />
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    }}>
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{
        borderBottom: '1px solid #C5C5C5',
      }}>
        <Tab label="All" />
        {/* <Tab label="Cancel" />
        <Tab label="Business Plan" /> */}
      </Tabs>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Typography variant="h2" sx={{ fontSize: '31px', fontWeight: 400 }}>All Subscriptions</Typography>
        <Button
          disabled={subs.filter(sub => sub.is_checked).length <= 0}
          variant="contained"
          onClick={downloadCSV}
        >
          Export as CSV
        </Button>
      </Box>
      <Box
        sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
        component={'form'}
        onSubmit={(e) => {
          e.preventDefault();
          setSearch({
            ...search,
            search: e.target[0].value,
          });
        }}
      >
        <TextField
          fullWidth
          size="small"
          type="text"
          placeholder="Search subscriptions by customers' name"
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ borderRadius: '8px' }}
        >
          Search
        </Button>
        <Box>
          <Button
            variant={Boolean(search.payment_status) ? "contained" : "outlined"}
            sx={{
              color: '#373736',
              borderRadius: '8px',
            }}
            startIcon={<img src={icons.filter_list} alt="filter" />}
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </Button>
        </Box>
        <IconButton onClick={handleClick}>
          <img src={icons.arrow_up_down_round} alt="sort" />
        </IconButton>
        <Menu
          anchorEl={sortAnchorEl}
          open={openSortMenu}
          onClose={() => setSortAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { width: '160px' },
          }}
          slotProps={{
            paper: {
              sx: { borderRadius: '7px' },
            }
          }}
        >
          <MenuItem
            selected={search.order_by === 'created_at' && search.order === 'asc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'created_at',
                order: 'asc',
              });
              setSortAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Asc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'created_at' && search.order === 'desc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'created_at',
                order: 'desc',
              });
              setSortAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Desc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'name' && search.order === 'asc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'name',
                order: 'asc',
              });
              setSortAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Asc)</ListItemText>
          </MenuItem>
          <MenuItem
            selected={search.order_by === 'name' && search.order === 'desc'}
            sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}
            onClick={() => {
              setSearch({
                ...search,
                order_by: 'name',
                order: 'desc',
              });
              setSortAnchorEl(null);
            }}
          >
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Desc)</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', p: '8px 20px' }}>
        <Checkbox
          checked={subs.filter(sub => sub.is_checked).length > 0}
          indeterminate={subs.filter(sub => sub.is_checked).length > 0 && subs.filter(sub => sub.is_checked).length < subs.length}
          onChange={handleAllChange}
          tabIndex={-1}
          disableRipple
        />
        <Typography sx={{ p: '8px 16px' }}>Select/Deselect All</Typography>
      </Box>
      <Divider sx={{ borderColor: '#BFBFBF' }} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
        {subs.map((sub, index: number) => <SubscriptionCard
          key={index}
          sub={sub}
          setAnchorEl={setAnchorEl}
          setSelectedSub={setSelectedSub}
          handleChange={() => handleChange(index)}
        />)}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => {
            setAnchorEl(null)
            setSelectedSub(selectedSub);
            setOpen(true);
          }} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            Cancel Subscription
          </MenuItem>
          <MenuItem onClick={() => {
            downloadPDF();
            setAnchorEl(null)
          }} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            Download PDF
          </MenuItem>
          <MenuItem onClick={() => {
            setAnchorEl(null)
          }} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            Freeze Account
          </MenuItem>
        </Menu>
        <NFCInvoice order={selectedSub} />
      </Box>
    </Box>
  </>
}