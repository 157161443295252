import React, { useEffect, useState } from "react";
import { Box, Typography, styled, Paper, TextField, Button } from "@mui/material";
import FileHelper from "../../../lib/helpers/fileHelper";
import { useLocation, useNavigate } from "react-router-dom";
import adminApi from "../../../app/api/admin";
import { Formik } from "formik";
import AddItemDialog from "../component/addItemDialog";
import productApi from "../../../app/api/product";

const DetailColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: '0 2rem',
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OrderCreateComponent() {
  const query = useQuery();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [products, setProducts] = useState<any[]>();
  const [openAddItem, setOpenAddItem] = useState(false);

  useEffect(() => {
    adminApi.getUserDetails(`${query.get('user_uuid') ?? ''}`).then(async (response) => {
      setUser(response.data);
    });
    // adminApi.getOrderDetails(`${uuid}`).then(({ data }) => {
    //   setOrder(data);
    //   Promise.all(data.order_products.map(async (product) => {
    //     try {
    //       return {
    //         ...product,
    //         front_thumbnail: product.front_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.front_thumbnail) : product.front_thumbnail,
    //         back_thumbnail: product.back_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.back_thumbnail) : product.back_thumbnail,
    //         sample_thumbnail: Boolean(product.sample_thumbnail) ? product.sample_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.sample_thumbnail) : product.sample_thumbnail : '',
    //       }
    //     } catch (err) {
    //       return {
    //         ...product,
    //         front_thumbnail: undefined,
    //         back_thumbnail: undefined,
    //         sample_thumbnail: undefined,
    //       }
    //     }
    //   })).then(setOrderProducts);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    productApi.getProducts({}).then(response => {
      Promise.all(response.data.map(async (product) => {
        try {
          return {
            ...product,
            card_thumbnail: product.card_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.card_thumbnail) : product.card_thumbnail,
          }
        } catch (err) {
          return {
            ...product,
            card_thumbnail: undefined,
          }
        }
      })).then(res => {
        setProducts(res);
      });
    });
  }, []);

  return <Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      <Box>
        <Typography fontWeight='500'>Create New Order</Typography>
      </Box>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Customer Details</Typography>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          py: '1rem',
        }}>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Name</Typography>
            <Typography variant="body1">{user?.name}</Typography>
          </DetailColumn>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Email</Typography>
            <Typography variant="body1">{user?.email}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="h4">Referral Code</Typography>
            <Typography variant="body1">{user?.referral_code ?? '-'}</Typography>
          </DetailColumn>
        </Box>
      </Paper>

      <Formik
        initialValues={{
          user_uuid: query.get('user_uuid') ?? user?.uuid ?? '',
          address1: user?.shipping_address?.address_1,
          address2: user?.shipping_address?.address_2,
          city: '',
          state: '',
          postcode: '',
          country: '',
          shipping_fee: 0,
          items: [] as any[],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          adminApi.addOrder(values)
            .then(res => navigate(`/portal/orders/${res.data.order_uuid}`))
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {/* A hack to preload images */}
            <div style={{ position: "fixed", width: '100%' }}>
              {products?.map(({ card_thumbnail }, index) => <img key={index} src={card_thumbnail} style={{ height: '0px', width: '0px' }} alt="front thumbnail" />)}
            </div>
            <AddItemDialog
              open={openAddItem}
              selectedUser={user}
              handleClose={() => setOpenAddItem(false)}
              postSubmit={() => setOpenAddItem(false)}
              products={products}
              items={values.items}
              setFieldValue={setFieldValue}
            />
            <Paper sx={{ p: '20px' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Shipping Info</Typography>
              <Box sx={{
                display: 'flex',
                gap: '5px',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                py: '1rem',
              }}>
                {/* <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">Name</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="name"
                    // value={variation.name}
                    value={values.name}
                    // onChange={e => setFieldValue(`variations[${i}].name`, e.target.value)}
                    onChange={handleChange}
                  // onBlur={handleBlur}
                  />
                </DetailColumn>
                <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">Phone Number</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="phone"
                    // value={variation.name}
                    value={values.phone}
                    // onChange={e => setFieldValue(`variations[${i}].name`, e.target.value)}
                    onChange={handleChange}
                  // onBlur={handleBlur}
                  />
                </DetailColumn> */}
                <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">Address</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="address1"
                    placeholder="Address line 1"
                    value={values.address1}
                    onChange={handleChange}
                  />
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="address2"
                    placeholder="Address line 2"
                    value={values.address2}
                    onChange={handleChange}
                  />
                </DetailColumn>
                <DetailColumn>
                  <Typography variant="h4">Shipping Fee</Typography>
                  {/* <Typography variant="body1">{user?.shipping_address?.bill_mobile}</Typography> */}
                  <TextField
                    type="number"
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="shipping_fee"
                    value={values.shipping_fee}
                    onChange={handleChange}
                  />
                </DetailColumn>
              </Box>
              <Box sx={{
                display: 'flex',
                gap: '5px',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                py: '1rem',
              }}>
                <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">City</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                </DetailColumn>
                <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">State</Typography>
                  {/* <Typography variant="body1">{user?.shipping_address?.bill_mobile}</Typography> */}
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                  />
                </DetailColumn>
                <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                  <Typography variant="h4">Postal Code</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="postcode"
                    placeholder="Postal code"
                    value={values.postcode}
                    onChange={handleChange}
                  />
                </DetailColumn>
                <DetailColumn>
                  <Typography variant="h4">Country</Typography>
                  <TextField
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  />
                </DetailColumn>
              </Box>
            </Paper>

            <Paper sx={{ p: '20px', mt: '20px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Items</Typography>
                <Button onClick={() => {
                  setOpenAddItem(true);
                }}>+ Add Item</Button>
              </Box>
              {(values.items ?? []).map((product, index) => <>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', my: '20px' }}>
                  <img src={product.card_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="front thumbnail" />
                  {/* <img src={product.back_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="back thumbnail" /> */}
                </Box>
                <Box key={index} sx={{
                  display: 'flex',
                  gap: '5px',
                  flexWrap: 'nowrap',
                  justifyContent: 'flex-start',
                  py: '1rem',
                }}>
                  <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                    <Typography variant="h4">Product Name</Typography>
                    <Typography variant="body1">{product.card_name}</Typography>
                  </DetailColumn>
                  <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                    <Typography variant="h4">Name on Card</Typography>
                    <Typography variant="body1">{product.name}</Typography>
                  </DetailColumn>
                  <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                    <Typography variant="h4">Position</Typography>
                    <Typography variant="body1">{product.position}</Typography>
                  </DetailColumn>
                  <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                    <Typography variant="h4">Price</Typography>
                    <Typography variant="body1">{product.price}</Typography>
                  </DetailColumn>
                  <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
                    <Typography variant="h4">Quantity</Typography>
                    <Typography variant="body1">{product.quantity}</Typography>
                  </DetailColumn>
                  <DetailColumn>
                    <Typography variant="h4">Total</Typography>
                    <Typography variant="body1">{product.price * product.quantity}</Typography>
                  </DetailColumn>
                </Box>
              </>)}
            </Paper>
            <Button type="submit" variant="contained" disabled={isSubmitting} sx={{ mt: '20px' }}>
              Submit
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  </Box>
}