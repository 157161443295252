import React, { useEffect, useState } from "react";
import { Box, Typography, styled, Paper } from "@mui/material";
import FileHelper from "../../../lib/helpers/fileHelper";
import { useParams } from "react-router-dom";
import adminApi from "../../../app/api/admin";

const baseUrl = 'https://member.pixelcard.co';

const DetailColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: '0 2rem',
});

export default function OrderDetailsComponent() {
  const { uuid } = useParams();
  const [order, setOrder] = useState<any>();
  const [orderProducts, setOrderProducts] = useState<any[]>();

  useEffect(() => {
    adminApi.getOrderDetails(`${uuid}`).then(({ data }) => {
      setOrder(data);
      Promise.all(data.order_products.map(async (product) => {
        try {
          return {
            ...product,
            front_thumbnail: product.front_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.front_thumbnail) : product.front_thumbnail,
            back_thumbnail: product.back_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.back_thumbnail) : product.back_thumbnail,
            sample_thumbnail: Boolean(product.sample_thumbnail) ? product.sample_thumbnail.slice(0, 5) !== 'https' ? await FileHelper.getUrl(product.sample_thumbnail) : product.sample_thumbnail : '',
          }
        } catch (err) {
          return {
            ...product,
            front_thumbnail: undefined,
            back_thumbnail: undefined,
            sample_thumbnail: undefined,
          }
        }
      })).then(setOrderProducts);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      <Box>
        <Typography fontWeight='500'>Order Detail {order?.order_ref_id}</Typography>
      </Box>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Customer Details</Typography>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          py: '1rem',
        }}>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Name</Typography>
            <Typography variant="body1">{order?.customer.name}</Typography>
          </DetailColumn>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Referral Code</Typography>
            <Typography variant="body1">{order?.customer.referral_code}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="h4">Date</Typography>
            <Typography variant="body1">{order?.created_at}</Typography>
          </DetailColumn>
        </Box>
      </Paper>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Shipping Info</Typography>
        <Box sx={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          py: '1rem',
        }}>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Name</Typography>
            <Typography variant="body1">{order?.customer.name}</Typography>
          </DetailColumn>
          <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
            <Typography variant="h4">Phone Number</Typography>
            <Typography variant="body1">{order?.order_payment?.bill_mobile}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="h4">Address</Typography>
            <Typography variant="body1">{order?.order_shipping?.address_1}, {order?.order_shipping?.address_2}</Typography>
          </DetailColumn>
        </Box>
      </Paper>

      <Paper sx={{ p: '20px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Product Details</Typography>
        {(orderProducts ?? []).map((product, index) => <>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', my: '20px' }}>
            <img src={product.front_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="front thumbnail" />
            <img src={product.back_thumbnail} style={{ height: '175px', borderRadius: '8px' }} alt="back thumbnail" />
          </Box>
          <Box key={index} sx={{
            display: 'flex',
            gap: '5px',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            py: '1rem',
          }}>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Product Name</Typography>
              <Typography variant="body1">{product.card_name}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Name on Card</Typography>
              <Typography variant="body1">{product.name}</Typography>
            </DetailColumn>
            <DetailColumn sx={{ borderRight: '1px solid #C5C5C5' }}>
              <Typography variant="h4">Position</Typography>
              <Typography variant="body1">{product.position}</Typography>
            </DetailColumn>
            {Boolean(product.serial_number) && <DetailColumn>
              <Typography variant="h4">Serial No.</Typography>
              <a href={`${baseUrl}/c/${product.serial_number}`}>
              <Typography variant="body1">{product.serial_number}</Typography>
              </a>
            </DetailColumn>}
          </Box>
        </>)}
      </Paper>
    </Box>
  </Box>
}