import React, { useEffect, useState, MouseEvent } from "react";
import FileHelper from "../../lib/helpers/fileHelper";
import SubscriptionsComponent from "./subscriptions.component";
import Papa from "papaparse";
import adminApi from "../../app/api/admin";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

export default function SubscriptionsContainer() {
  const [search, setSearch] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const [reason, setReason] = useState('');
  const [subs, setSubs] = useState<any[]>([]);
  const [selectedSub, setSelectedSub] = useState<any>();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const PDFoptions = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'portrait',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
  };

  const getTargetElement = () => document.getElementById('invoice-template');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setSortAnchorEl(sortAnchorEl ? null : event.currentTarget);
  };

  const openSortMenu = Boolean(sortAnchorEl);

  const handleChange = (index: number) => {
    setSubs([
      ...subs.slice(0, index),
      {
        ...subs[index],
        is_checked: !subs[index].is_checked,
      },
      ...subs.slice(index + 1),
    ]);
  }

  const handleAllChange = event => {
    if (event.target.checked) {
      setSubs(subs.map(noti => ({
        ...noti,
        is_checked: true,
      })));
    } else {
      setSubs(subs.map(noti => ({
        ...noti,
        is_checked: false,
      })));
    }
  }

  const handleCancelSubscription = (order_uuid: string) => {
    adminApi.cancelSubscription(order_uuid)
    .then(res => getSubscriptions(search));
  }

  const downloadCSV = () => {
    const csv = Papa.unparse(
      subs
        .filter(sub => sub.is_checked)
        .map(({ is_checked, ...otherProps }) => ({ ...otherProps }))
    );

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'download.csv');
    tempLink.click();
  }

  const downloadPDF = () => {
    console.log('download PDF')
    generatePDF(getTargetElement, PDFoptions);
  }

  const getSubscriptions = (srch) => {
    adminApi.getSubscriptions(srch).then(response => {
      Promise.all((response.data).map(async (sub, i) => {
        let presigned_url = null;
        if (Boolean(sub.customer.image)) presigned_url = await FileHelper.getUrl(sub.customer.image);

        return {
          ...sub,
          status: sub.status,
          image: presigned_url,
          date: sub.order_payment.created_at,
          price: `${sub.currency}${sub.total_amount}`,
          plan: 'Lifestyle', // Currently only have one plan, in the future have to remove this hard-coded value
          is_checked: false,
        };
      })).then(setSubs);
    });
  }

  useEffect(() => {
    getSubscriptions(search);

    // if (selectedTab === 1) {
    //   subscriptions = subscriptions.filter(sub => sub.status === 'cancelled');
    // } else if (selectedTab === 2) {
    //   subscriptions = subscriptions.filter(sub => sub.plan === 'Business');
    // }
  }, [selectedTab, search]);

  return <SubscriptionsComponent
    subs={subs}
    open={open}
    openSortMenu={openSortMenu}
    handleClick={handleClick}
    setOpen={setOpen}
    openFilter={openFilter}
    setOpenFilter={setOpenFilter}
    anchorEl={anchorEl}
    setAnchorEl={setAnchorEl}
    sortAnchorEl={sortAnchorEl}
    setSortAnchorEl={setSortAnchorEl}
    search={search}
    setSearch={setSearch}
    reason={reason}
    setReason={setReason}
    selectedSub={selectedSub}
    setSelectedSub={setSelectedSub}
    selectedTab={selectedTab}
    handleTabChange={handleTabChange}
    handleChange={handleChange}
    handleAllChange={handleAllChange}
    handleCancelSubscription={handleCancelSubscription}
    downloadCSV={downloadCSV}
    downloadPDF={downloadPDF}
  />
}